/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import "@mui/x-date-pickers-pro";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox/MDBox";
import MDTypography from "../../components/MDTypography/index";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";
import MDButton from "../../components/MDButton";
// import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  MobileTimePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { useMutation } from "../../api/customApi";
import { apiendpoint } from "../../api/endpoint";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import useAttendanceTableData from "./data/useAttendanceTableData";
import { toast } from "react-toastify";

function Attendance() {
  const token = localStorage.getItem("auth");
  const navigate = useNavigate();
  const { makeRequest, isLoading } = useMutation();
  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [empInfo, setEmpInfo] = useState([]);
  const [currentAttendance, setCurrentAttendance] = useState(null);
  const [inputValue, setInputValue] = useState({
    in: "",
    out: "",
    Date: "",
    emp_detail: "",
  });

  //new

  // For Filter
  const [dateRange, setDateRange] = useState(null);
  const [filterOption, setFilterOption] = useState("month"); // filter option
  const [filterChange, setFilterChange] = useState(false);

  // Handle Edit Click
  const handleEditClick = (attendance) => {
    console.log("Edit button clicked for attendance:", attendance);
    setCurrentAttendance(attendance);
    setInputValue({
      in: attendance.attributes.in,
      out: attendance.attributes.out,
      Date: attendance.attributes.Date,
      emp_detail: attendance.attributes.emp_detail.data.id,
    });
    setEditMode(true);
    setShow(true);
  };

  const { columns, rows } = useAttendanceTableData(handleEditClick, dateRange);

  useEffect(() => {
    populateEmployeesId();
  }, []);

  const populateEmployeesId = async () => {
    try {
      const response = await makeRequest({
        type: "get",
        url: apiendpoint.info,
        token: token,
      });
      if (response) {
        setEmpInfo(response.data);
      }
    } catch (error) {
      console.error("Error fetching employee info:", error);
    }
  };

  // hide the popups
  const handleClose = () => setShow(false);

  const handleAddAttendanceButton = () => {
    setEditMode(false);
    setInputValue({
      in: "",
      out: "",
      Date: "",
      emp_detail: "",
    });
    setShow(true);
  };

  // Add the daily attendance
  const handleAddAttendance = async (e) => {
    e.preventDefault();
    try {
      const empAttendanceRespone = await makeRequest({
        type: "post",
        url: apiendpoint.postAttendance,
        token: token,
        body: {
          data: {
            ...inputValue,
          },
        },
      });
      if (empAttendanceRespone) {
        toast.success(`Daily Attendance Added Successfully!`);
        setInputValue(empAttendanceRespone.data);
        setShow(false);
        navigate("/attendance", { state: { refreshed: true } });
      }
    } catch (error) {
      toast.error("Failed to add attendance. Please try again.");
      console.error("Error adding attendance:", error);
    }
  };

  // Select the Employee
  const handleSelectOption = (e) => {
    setInputValue((prev) => ({ ...prev, emp_detail: e.target.value }));
  };

  //  Edit the attendance details
  const handleEditAttendance = async (e) => {
    e.preventDefault();
    try {
      const response = await makeRequest({
        type: "put",
        url: apiendpoint.editAttendance(currentAttendance.id),
        token: token,
        body: {
          data: {
            ...inputValue,
          },
        },
      });
      if (response) {
        toast.success("Attendance updated successfully!");
        setInputValue(response.data);
        setShow(false);
        navigate("/attendance", { state: { refreshed: true } });
      }
    } catch (error) {
      toast.error("Failed to update attendance. Please try again.");
      console.error("Error updating attendance:", error);
    }
  };

  // filter handle
  useEffect(() => {
    // Update dateRange based on the selected filterOption
    handleDateFilterChange(filterOption);
  }, [filterOption]);

  const handleDateFilterChange = (filterType) => {
    let startDate, endDate;
    const today = dayjs().startOf("day");
    switch (filterType) {
      case "today":
        startDate = endDate = today.format("YYYY-MM-DD");
        break;
      case "week":
        startDate = today.startOf("week").format("YYYY-MM-DD");
        endDate = today.endOf("week").format("YYYY-MM-DD");
        break;
      case "month":
        startDate = today.startOf("month").format("YYYY-MM-DD");
        endDate = today.endOf("month").format("YYYY-MM-DD");
        break;
      case "custom":
        startDate = dateRange?.startDate;
        endDate = dateRange?.endDate;
        break;
      default:
        startDate = endDate = null;
    }

    setDateRange({ startDate, endDate });
  };

  const handleFilterChange = (event) => {
    setFilterOption(event.target.value);
    setFilterChange(true);
  };

  // Change the Date Range
  const handleCustomDateChange = (newValue, type) => {
    setDateRange((prev) => ({
      ...prev,
      [type]: newValue.format("YYYY-MM-DD"),
    }));
  };

  const handleClearFilter = () => {
    setDateRange(null);
    setFilterChange(false);
  };
  useEffect(() => {
    if (
      filterOption === "custom" &&
      dateRange?.startDate &&
      dateRange?.endDate
    ) {
      handleDateFilterChange("custom");
    }
  }, []);

  const today = dayjs();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}></Grid>
        </Grid>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
            variant="gradient"
            bgColor="warning"
            borderRadius="lg"
            coloredShadow="dark"
          >
            <MDTypography variant="h6" color="white">
              Attendance List
            </MDTypography>
            <MDButton variant="gradient" onClick={handleAddAttendanceButton}>
              Add Attendance
            </MDButton>
          </MDBox>

          {/* Filter option */}
          <MDBox
            mx={2}
            my={2}
            mb={0}
            display="flex"
            justifyContent="space-between"
          >
            <MDBox display="flex" alignItems="center">
              <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <Select
                  labelId="filter-select-label"
                  id="filter-select"
                  value={filterOption}
                  onChange={handleFilterChange}
                  label="Filter By"
                  displayEmpty
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "center",
                      horizontal: "center",
                    },
                    transformOrigin: {
                      vertical: "center",
                      horizontal: "center",
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    <em>Selected Date</em>
                  </MenuItem>
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="week">This Week</MenuItem>
                  <MenuItem value="month">This Month</MenuItem>
                  <MenuItem value="custom">Date Range</MenuItem>
                </Select>
              </FormControl>{" "}
              &nbsp;
              <MDButton
                variant="gradient"
                color="error"
                size="small"
                disabled={!filterChange}
                onClick={handleClearFilter}
              >
                Reset
              </MDButton>
            </MDBox>

            {filterOption === "custom" && (
              <MDBox display="flex" alignItems="center">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    value={
                      dateRange?.startDate ? dayjs(dateRange.startDate) : null
                    }
                    onChange={(newValue) =>
                      handleCustomDateChange(newValue, "startDate")
                    }
                    disableFuture
                  />
                  <MDBox mx={2}>to</MDBox>
                  <DatePicker
                    label="End Date"
                    value={dateRange?.endDate ? dayjs(dateRange.endDate) : null}
                    onChange={(newValue) =>
                      handleCustomDateChange(newValue, "endDate")
                    }
                    disableFuture
                  />
                </LocalizationProvider>
              </MDBox>
            )}
            <MDBox mx={2} my={2} display="flex" justifyContent="space-between">
              {dateRange?.startDate && dateRange?.endDate && (
                <MDBox display="flex" alignItems="center">
                  <MDTypography
                    variant="caption"
                    color="text"
                    ml={2}
                    fontWeight="bold"
                  >
                    {filterOption === "today"
                      ? `Today: ${dayjs(dateRange.startDate).format(
                          "YYYY-MM-DD"
                        )}`
                      : `From: ${dayjs(dateRange.startDate).format(
                          "YYYY-MM-DD"
                        )} - To: ${dayjs(dateRange.endDate).format(
                          "YYYY-MM-DD"
                        )}`}
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
          </MDBox>
          {/* end filter */}

          {/* Popup Modal for Add or Edit attendance */}
          <Modal
            keepMounted
            open={show}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <MDBox>
              <Card
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  boxShadow: 1,
                  borderRadius: 5,
                  p: 2,
                  width: "40vw",
                  minWidth: 300,
                }}
              >
                <MDBox
                  display="grid"
                  variant="gradient"
                  bgColor="warning"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={2}
                  mt={-3}
                  p={3}
                  mb={1}
                  textAlign="center"
                >
                  <MDTypography
                    gridRow="1"
                    gridColumn="3/7"
                    variant="h5"
                    fontWeight="medium"
                    color="white"
                    mt={1}
                  >
                    {editMode
                      ? "Edit Employee Attendance"
                      : "Daily Employee Attendance"}
                  </MDTypography>
                  <MDTypography
                    gridRow="2"
                    gridColumn="3/7"
                    variant="button"
                    color="white"
                  >
                    {editMode ? "Update Attendance" : "Add Daily Attendance"}
                  </MDTypography>
                  <MDTypography gridColumn="8" gridRow="1/2" variant="button">
                    <IconButton
                      size="medium"
                      color="white"
                      onClick={handleClose}
                    >
                      <Icon fontSize="large" fontWeight="900">
                        close
                      </Icon>
                    </IconButton>
                  </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    display="grid"
                    onSubmit={
                      editMode ? handleEditAttendance : handleAddAttendance
                    }
                  >
                    <MDBox mb={1} gridRow="1" gridColumn="1/2">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileTimePicker
                          label="Check In"
                          value={dayjs(inputValue.in)}
                          onChange={(e) => {
                            setInputValue((prev) => ({
                              ...prev,
                              in: e.format("hh:mm:ss.SSS"),
                            }));
                          }}
                          format="hh:mm A"
                        />
                      </LocalizationProvider>
                    </MDBox>
                    <MDBox mb={1} gridRow="1" gridColumn="2/4">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileTimePicker
                          label="Check Out"
                          value={dayjs(inputValue.out)}
                          onChange={(e) => {
                            setInputValue((prev) => ({
                              ...prev,
                              out: e.format("hh:mm:ss.SSS"),
                            }));
                          }}
                          format="hh:mm A"
                        />
                      </LocalizationProvider>
                    </MDBox>
                    <MDBox mb={1} gridRow="2" gridColumn="1/2">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Current Date"
                          slotProps={{
                            textField: {
                              variant: "standard",
                              fullWidth: true,
                            },
                          }}
                          defaultValue={today}
                          disableFuture
                          value={dayjs(inputValue.Date)}
                          onChange={(e) => {
                            console.log(e.format("YYYY-MM-DD"), "today date");
                            setInputValue((prev) => ({
                              ...prev,
                              Date: e.format("YYYY-MM-DD"),
                            }));
                          }}
                        />
                      </LocalizationProvider>
                    </MDBox>
                    <MDBox
                      m={1}
                      ml={4}
                      gridRow="2"
                      gridColumn="2/4"
                      textTransform="capitalize"
                    >
                      <FormControl variant="standard" fullWidth>
                        <InputLabel id="select-standard-label">
                          Selected Employee
                        </InputLabel>
                        <Select
                          id="select-standard"
                          defaultValue=" "
                          value={inputValue.emp_detail}
                          onChange={(e) => handleSelectOption(e)}
                        >
                          {empInfo.map((option, index) =>
                            option.attributes.status === "active" ? (
                              <MenuItem value={option.id} key={index}>
                                {option.attributes.name}
                              </MenuItem>
                            ) : null
                          )}
                        </Select>
                      </FormControl>
                    </MDBox>

                    <MDBox pt={2} gridRow="3" gridColumn="3/4">
                      <MDButton
                        size="small"
                        variant="gradient"
                        color="light"
                        onClick={() => setShow(false)}
                      >
                        Close
                      </MDButton>{" "}
                      <MDButton
                        size="small"
                        variant="gradient"
                        color="warning"
                        type="submit"
                      >
                        {editMode ? "Update" : "Add"}
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </MDBox>
          </Modal>
          {/* end Modal */}

          {/* Data Table */}
          <MDBox pt={3} textAlign="center">
            {isLoading ? (
              <LinearProgress color="warning" />
            ) : rows.length !== 0 ? (
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            ) : (
              "Not data Found"
            )}
          </MDBox>
          {/* end table */}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Attendance;
