/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useMutation } from "../../../api/customApi";
import { apiendpoint } from "../../../api/endpoint";
import { useLocation } from "react-router-dom";
import MDTypography from "../../../components/MDTypography";
import moment from "moment"

export default function holidaysTableData() {
  const [holidays, setHolidays] = useState([]);
  const { makeRequest } = useMutation();
  const token = localStorage.getItem("auth");
  const location = useLocation();
  useEffect(() => {
    if (location.state?.refreshed) {
      holidaysList();
    } else {
      holidaysList();
    }
  }, [location.state]);

  const holidaysList = async () => {
    try {
      const response = await makeRequest({
        type: "get",
        url: apiendpoint.getHolidaysList,
        token: token,
      });
      if (response) {
        setHolidays(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };



  return {
    columns: [
      { Header: "holiday name", accessor: "holiday_name", align: "center" },
      { Header: "holiday date", accessor: "holiday_date", align: "center" },
      { Header: "holiday day", accessor: "holiday_day", align: "center" },

    ],
    rows: holidays.map((days) => ({
      
      holiday_name: (
        <MDTypography
          display="block"
          variant="caption"
          color="text"
          fontWeight="medium"
          textTransform="capitalize"
        
        >
          {days.attributes.Name}
        </MDTypography>
      ),
      holiday_date: (
        <MDTypography
          display="block"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {moment(days.attributes.date).format("DD/MM/YYYY")}
        </MDTypography>
      ),
      holiday_day: (
        <MDTypography
          display="block"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {moment(days.attributes.date).format("dddd")}
        </MDTypography>
      ),
    })),
  };
}
