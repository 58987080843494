/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox/MDBox";
import MDTypography from "../../../components/MDTypography/index";
import MDButton from "../../../components/MDButton";
import { useEffect, useState } from "react";
import { useMutation } from "../../../api/customApi";
import { apiendpoint } from "../../../api/endpoint";
import { useLocation, useNavigate } from "react-router-dom";

export default function useAttendanceTableData(onEdit, dateRange) {
  const [empAttendance, setEmpAttendance] = useState([]);
  const { makeRequest } = useMutation();
  const navigate = useNavigate();

  const token = localStorage.getItem("auth");
  const location = useLocation();

  useEffect(() => {
    if (location.state?.refreshed) {
      populateEmployeesAttendance();
    } else {
      populateEmployeesAttendance();
    }
  }, [location.state, dateRange]);

  const populateEmployeesAttendance = async () => {
    try {
      let url = apiendpoint.getAttendance;
      if (dateRange) {
        const { startDate, endDate } = dateRange;
        url = apiendpoint.filterDateRange(startDate, endDate);
      }
      const response = await makeRequest({
        type: "get",
        url: url,
        token: token,
      });
      if (response) {
        setEmpAttendance(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onPressEdit = (id) => {
    console.log(id, "get");
    const selectedAttendance = empAttendance.find((att) => att.id === id);
    console.log(selectedAttendance, "Data");
    if (onEdit) {
      onEdit(selectedAttendance);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Do you want to delete?") === true) {
      if (id) {
        try {
          const token = localStorage.getItem("auth");
          const response = await makeRequest({
            type: "delete",
            url: apiendpoint.deleteAttendance(id),
            token: token,
          });
          if (response) {
            navigate("/attendance", { state: { refreshed: true } });
          }
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      navigate("/attendance", { state: { refreshed: true } });
    }
  };

  const Employee = ({ name, image }) => {
    let myImage = `${process.env.REACT_APP_BASE_URL}${image}`;
    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <img
          src={myImage}
          alt=""
          className="size-12 object-center rounded-full"
        />
        <MDBox ml={2} lineHeight={1}>
          <MDTypography
            display="block"
            variant="caption"
            color="text"
            textTransform="capitalize"
            fontWeight="bold"
          >
            {name}
          </MDTypography>
        </MDBox>
      </MDBox>
    );
  };

  return {
    columns: [
      {
        Header: "employee name",
        accessor: "employee_name",
        width: "30%",
        align: "left",
      },
      { Header: "checkin time", accessor: "in", align: "left" },
      { Header: "checkout time", accessor: "out", align: "center" },
      { Header: "date", accessor: "date", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: empAttendance.map((emp) => ({
      employee_name: (
        <Employee
          name={emp.attributes.emp_detail?.data?.attributes?.name}
          image={
            emp.attributes.emp_detail.data?.attributes?.Photo?.data?.[0]
              ?.attributes?.url
          }
        />
      ),
      in: (
        <MDTypography
          display="block"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {emp.attributes.in}
        </MDTypography>
      ),
      out: (
        <MDTypography
          display="block"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {emp.attributes.out}
        </MDTypography>
      ),
      date: (
        <MDTypography
          display="block"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {emp.attributes.Date}
        </MDTypography>
      ),
      action: (
        <>
          <MDButton
            variant="text"
            color="text"
            size="small"
            onClick={() => onPressEdit(emp.id)}
          >
            <Icon>edit</Icon>&nbsp;edit
          </MDButton>
          <MDButton
            variant="text"
            color="error"
            size="small"
            onClick={() => handleDelete(emp.id)}
          >
            <Icon>delete</Icon>&nbsp;delete
          </MDButton>
        </>
      ),
    })),
  };
}
