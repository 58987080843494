export const apiendpoint =  {
  login : `${process.env.REACT_APP_BASE_URL}/api/auth/local`,
  
  //Endpoint for Employee
  info:`${process.env.REACT_APP_BASE_URL}/api/emp-details/?populate=*&sort=id`,
  addinfo:`${process.env.REACT_APP_BASE_URL}/api/emp-details/?populate=*`,
  infoEdit:(id:number)=>`${process.env.REACT_APP_BASE_URL}/api/emp-details/${id}/?populate=*`,

  // Upload image
  uploadImg:`${process.env.REACT_APP_BASE_URL}/api/upload`,
  getuploadImg:(id:number)=>`${process.env.REACT_APP_BASE_URL}/api/upload/files/${id}`,

  //Endpoint for Attendance
  getAttendance:`${process.env.REACT_APP_BASE_URL}/api/daily-attendances/?populate=*&sort=id`,
  postAttendance:`${process.env.REACT_APP_BASE_URL}/api/daily-attendances`,
  editAttendance:(id:number)=>`${process.env.REACT_APP_BASE_URL}/api/daily-attendances/${id}/?populate=*`,
  deleteAttendance:(id:number)=>`${process.env.REACT_APP_BASE_URL}/api/daily-attendances/${id}`,
  filterDateRange:(startDate:string, endDate:string)=>`${process.env.REACT_APP_BASE_URL}/api/daily-attendances/?populate=*&sort=id&filters[Date][$gte]=${startDate}&filters[Date][$lte]=${endDate}`,

  //Endpoint for Holidays
  getHolidaysList:`${process.env.REACT_APP_BASE_URL}/api/holiday-lists?sort=id`,
  postHolidays:`${process.env.REACT_APP_BASE_URL}/api/holiday-lists`,

  // Blogs Endpoint
  getBlogsList:`${process.env.REACT_APP_BASE_URL}/api/add-blogs?populate=*&sort=id`,
  postBlogs:`${process.env.REACT_APP_BASE_URL}/api/add-blogs`,
  editBlogs:(id:number)=>`${process.env.REACT_APP_BASE_URL}/api/add-blogs/${id}/?populate=*`,
  deleteBlogs:(id:number)=>`${process.env.REACT_APP_BASE_URL}/api/add-blogs/${id}`,

};
