import { CssBaseline, ThemeProvider } from "@mui/material";
import React, { useEffect, useState } from "react";
import brandWhite from "../../assets/images/logo-ct.webp";
import theme from "../../assets/theme";
import {
  setMiniSidenav,
  useMaterialUIController,
} from "../../context/MaterialUIProvider";
import Sidenav from "../../examples/Sidenav";
import routes from "../../routes";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const ThemeComponent = ({ children }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, sidenavColor,  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <div className="bg-white overflow-y-scroll">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Sidenav
          color={sidenavColor}
          brand={brandWhite}
          brandName="XYZ Studio" 
          routes={routes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
        {children}
      </ThemeProvider>
      <ToastContainer icon={false} style={{
        width:"30vw"
      }}/>
    </div>
  );
};

export default ThemeComponent;
