// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  background-color: rgb(227, 227, 227);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
  outline: none;
}


input[type="checkbox"]::after {
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\\f00c";
  font-size: 17px;
  color: white;
  display: none;
}

/* input[type="checkbox"]:hover {
  background-color: aquamarine;
} */

input[type="checkbox"]:checked {
  background-color: #ed820a;
  color: #fff;
}
input[type="checkbox"]:checked::after {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/components/Checkbox/Checkbox.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,wBAAwB;EACxB,YAAY;EACZ,WAAW;EACX,oCAAoC;EACpC,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,aAAa;AACf;;;AAGA;IACI,kCAAkC,EAAE,gBAAgB,EAAE,gBAAgB;EACxE,eAAe;EACf,YAAY;EACZ,aAAa;AACf;;AAEA;;GAEG;;AAEH;EACE,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,cAAc;AAChB","sourcesContent":["input[type=\"checkbox\"] {\n  appearance: none;\n  -webkit-appearance: none;\n  height: 18px;\n  width: 18px;\n  background-color: rgb(227, 227, 227);\n  border-radius: 3px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: 3px;\n  outline: none;\n}\n\n\ninput[type=\"checkbox\"]::after {\n    font-family: \"Font Awesome 5 Free\"; font-weight: 900; content: \"\\f00c\";\n  font-size: 17px;\n  color: white;\n  display: none;\n}\n\n/* input[type=\"checkbox\"]:hover {\n  background-color: aquamarine;\n} */\n\ninput[type=\"checkbox\"]:checked {\n  background-color: #ed820a;\n  color: #fff;\n}\ninput[type=\"checkbox\"]:checked::after {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
