/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import "./Checkbox.css";
interface CheckboxProps {
  label: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ label }) => {
  return (
    <div className="flex">
      <input type="checkbox"  defaultChecked/>
      <label>{label}</label>
    </div>
  );
};

export default Checkbox;
