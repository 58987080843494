// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import "@mui/x-date-pickers-pro";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox/MDBox";
import MDTypography from "../../components/MDTypography/index";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";

// Data
import authorsTableData from "./data/authorsTableData";
import MDButton from "../../components/MDButton";
import { useNavigate } from "react-router-dom";

function Employees() {
  const { columns, rows } = authorsTableData();
  const navigate = useNavigate();
  const hadleAddEmp = () => {
    navigate("/employees/register");
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}></Grid>
        </Grid>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
            variant="gradient"
            bgColor="warning"
            borderRadius="lg"
            coloredShadow="dark"
          >
            <MDTypography variant="h6" color="white">
              Employee List
            </MDTypography>
            <MDButton variant="gradient" onClick={hadleAddEmp}>
              Add Employee
            </MDButton>
          </MDBox>
          <MDBox pt={3} textAlign="center">
            { rows.length !== 0 ? (
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            ) : (
              "Not data Found"
            )}
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Employees;
