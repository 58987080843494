// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="file"]::file-selector-button {
    background-color:orange;
    color: white;
    padding: 7px  15px;
    margin-top: -5px;
    border: none;
    border-radius: 8px 8px 0 0 ;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/examples/Form/styles.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,2BAA2B;IAC3B,eAAe;AACnB","sourcesContent":["input[type=\"file\"]::file-selector-button {\n    background-color:orange;\n    color: white;\n    padding: 7px  15px;\n    margin-top: -5px;\n    border: none;\n    border-radius: 8px 8px 0 0 ;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
