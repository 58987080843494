/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MDBox from "../../../components/MDBox/MDBox";
import MDButton from "../../../components/MDButton";
import { Image } from "react-bootstrap";
import MDInput from "../../../components/MDInput";
import MDTypography from "../../../components/MDTypography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import {
  Card,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { apiendpoint } from "../../../api/endpoint";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../Navbars/DashboardNavbar";
import { toast } from "react-toastify";
import "../styles.css";
import { useMutation } from "../../../api/customApi";

const EditDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [imagePath, setImagePath] = useState("");
  const [changeImage, setChangeImage] = useState("");
  const [editData, setEditData] = useState({
    name: "",
    designation: "",
    empCode: "",
    phoneNumber: "",
    joiningDate: "",
    email: "",
    status: "",
    leave_balance: "",
    Photo: [],
  });

  const { makeRequest } = useMutation();
  const token = localStorage.getItem("auth");
  useEffect(() => {
    fetchEmployeeDetails();
  }, []);

  const fetchEmployeeDetails = async () => {
    try {
      const response = await makeRequest({
        type: "get",
        url: apiendpoint.infoEdit(id),
        token: token,
      });
      if (response) {
        setEditData(response.data.attributes);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => navigate("/employees");

  const hadleUpdate = async (e) => {
    e.preventDefault();
    try {
      let fieldsData = {
        ...editData,
      };
      if (imagePath) {
        const uploadImageId = await handleChangeImg();
        if (uploadImageId) {
          fieldsData = {
            ...fieldsData,
            Photo: [uploadImageId],
          };
        }
      } else {
        fieldsData = {
          ...fieldsData,
          Photo: [editData?.Photo?.data?.[0]?.id],
        };
      }
      try {
        makeRequest({
          type: "put",
          url: apiendpoint.infoEdit(id),
          token: token,
          body: {
            data: fieldsData,
          },
        });

        if (editData) {
          toast.success(`Update the ${editData.name} detail successfully !`);
          navigate("/employees", { state: { refreshed: true } });
        }
      } catch (error) {
        throw error;
      }
    } catch (error) {
      console.log(error, "API ERROR");
    }
  };

  // Upload the Image
  const handleChangeImg = async () => {
    const formData = new FormData();
    formData.append("files", changeImage);
    try {
      const uploadImageResponse = await axios.post(
        apiendpoint.uploadImg,
        formData
      );
      if (uploadImageResponse) {
        const photoId = uploadImageResponse.data[0].id;
        return photoId;
      }
    } catch (error) {
      if (error) {
        toast("Image upload failed", {
          type: "warning",
        });
      }
      throw new Error("Image upload failed");
    }
  };

  const option = ["active", "deactivate"];
  const handleSelectOption = (e) => {
    setEditData({
      ...editData,
      status: e.target.value,
    });
  };

  const srcUrl = `${process.env.REACT_APP_BASE_URL}${editData?.Photo?.data?.[0]?.attributes?.url}`;
  // console.log(srcUrl, "erdfdf");

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}></Grid>
        </Grid>
        <Card>
          <MDBox
            display="grid"
            variant="gradient"
            bgColor="warning"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography
              gridRow="1"
              gridColumn="3/7"
              variant="h5"
              fontWeight="medium"
              color="white"
              mt={1}
            >
              Edit Employee Information
            </MDTypography>
            <MDTypography
              gridRow="2"
              gridColumn="3/7"
              variant="button"
              color="white"
            >
              Enter details to edit
            </MDTypography>
            <MDTypography gridColumn="8" gridRow="1/2" variant="button">
              <IconButton size="medium" color="white" onClick={handleClose}>
                <Icon fontSize="large" fontWeight="900">
                  close
                </Icon>
              </IconButton>
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox
              component="form"
              role="form"
              display="grid"
              onSubmit={hadleUpdate}
            >
              <MDBox mb={1} gridRow="1" gridColumn="1/4">
                <MDInput
                  type="text"
                  label="Name"
                  variant="standard"
                  pattern="[A-Z]{1}[a-z]{2,}"
                  value={editData.name}
                  onChange={(e) => {
                    setEditData({ ...editData, name: e.target.value });
                  }}
                  fullWidth
                />
              </MDBox>
              <MDBox mb={1} gridRow="2" gridColumn="1/4">
                <MDInput
                  type="email"
                  label="Email"
                  position="relative"
                  variant="standard"
                  value={editData.email}
                  onChange={(e) => {
                    setEditData({ ...editData, email: e.target.value });
                  }}
                  fullWidth
                />
              </MDBox>
              <MDBox mb={1} gridRow="3" gridColumn="1/4">
                <MDInput
                  type="text"
                  label="Designation"
                  variant="standard"
                  value={editData.designation}
                  onChange={(e) => {
                    setEditData((editData) => ({
                      ...editData,
                      designation: e.target.value,
                    }));
                  }}
                  fullWidth
                />
              </MDBox>
              <MDBox mb={1} gridRow="4" gridColumn="1/4">
                <MDInput
                  type="text"
                  label="Phone Number"
                  variant="standard"
                  value={editData.phoneNumber}
                  onChange={(e) => {
                    setEditData({
                      ...editData,
                      phoneNumber: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </MDBox>
              <MDBox mb={1} gridRow="5" gridColumn="1/4">
                <MDInput
                  type="text"
                  label="Emp Code"
                  variant="standard"
                  value={editData.empCode}
                  onChange={(e) => {
                    setEditData({
                      ...editData,
                      empCode: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </MDBox>
              <MDBox
                mb={1}
                gridRow="6"
                gridColumn="1/4"
                textTransform="capitalize"
              >
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="select-standard-label">Status</InputLabel>
                  <Select
                    labelId="select-standard-label"
                    id="select-standard"
                    value={editData.status}
                    onChange={(e) => handleSelectOption(e)}
                    label="Status"
                  >
                    {option.map((option, index) => {
                      return (
                        <MenuItem value={option} key={index}>
                          {option}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </MDBox>
              <MDBox mb={1} mt={1} gridRow="7" gridColumn="1/4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Joining Date"
                    slotProps={{
                      textField: { variant: "standard", fullWidth: true },
                    }}
                    value={dayjs(editData.joiningDate)}
                    disableFuture
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        joiningDate: e.format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </LocalizationProvider>
              </MDBox>
              <MDBox mb={1} mt={1} ml={4} gridRow="7" gridColumn="4/8">
                <MDInput
                  type="text"
                  label="Leave Balance"
                  variant="standard"
                  value={editData.leave_balance}
                  onChange={(e) => {
                    setEditData({
                      ...editData,
                      leave_balance: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </MDBox>
              <MDBox
                mb={1}
                gridColumn="4/8"
                gridRow="1/7"
                textAlign="center"
                color="gray"
              >
                <MDTypography pt={2} pb={2} color="grey">
                  Upload Image:
                </MDTypography>
                <MDInput
                  type="file"
                  variant="standard"
                  accept="image/png, image/jpeg"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    const imgPath = URL.createObjectURL(file);
                    setChangeImage(file);
                    setImagePath(imgPath);
                  }}
                />
                {setImagePath && imagePath ? (
                  <MDBox
                    mt={2}
                    display="flex"
                    justifyContent="center"
                    borderRadius="rounded-full"
                    height="300px"
                  >
                    <Image
                      src={imagePath}
                      alt="image editDataiew"
                      className="max-h-[300px] max-w-[300px] rounded-full"
                    />
                  </MDBox>
                ) : (
                  <MDBox
                    mt={2}
                    display="flex"
                    justifyContent="center"
                    borderRadius="rounded-full"
                    height="300px"
                  >
                    <Image
                      src={srcUrl}
                      alt="No file"
                      className="max-h-[300px] max-w-[300px] rounded-full"
                    />
                  </MDBox>
                )}
              </MDBox>
              <MDBox mt={4} mb={1} gridRow="8" gridColumn="1/8">
                <MDButton
                  onChange={(e) => hadleUpdate(e)}
                  variant="gradient"
                  color="warning"
                  type="submit"
                >
                  Update
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default EditDetail;
