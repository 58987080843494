/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox/MDBox";
import MDTypography from "../../../components/MDTypography/index";
import MDBadge from "../../../components/MDBadge/index";

import { useEffect, useState } from "react";
import { apiendpoint } from "../../../api/endpoint";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "../../../api/customApi";
import { Icon } from "@mui/material";
import MDButton from "../../../components/MDButton";

export default function data() {
  const [empInfo, setEmpInfo] = useState([]);
  const navigate = useNavigate();
  const { makeRequest } = useMutation();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.refreshed) {
      populateEmployees();
    }else{
      populateEmployees();
    }
  }, [location.state]);
  const populateEmployees = async () => {
    try {
      const token = localStorage.getItem("auth");
      const response = await makeRequest({
        type: "get",
        url: apiendpoint.info,
        token: token,
      });
      if (response) {
        setEmpInfo(response.data);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hadleEdit = (id) => {
    navigate(`/employees/edit/${id}`);
  };
  const EmployeeName = ({ image, name, email }) => {
    let myImage = `${process.env.REACT_APP_BASE_URL}${image}`;
    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <img
          src={myImage}
          alt=""
          className="size-12 object-center rounded-full"
        />
        <MDBox ml={2} lineHeight={1}>
          <MDTypography
            display="block"
            variant="caption"
            color="text"
            textTransform="capitalize"
            fontWeight="bold"
          >
            {name}
          </MDTypography>
          <MDTypography variant="caption">{email}</MDTypography>
        </MDBox>
      </MDBox>
    );
  };

  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
    </MDBox>
  );
  return {
    columns: [
      {
        Header: "employee code",
        accessor: "employee_code",
        align: "left",
      },
      {
        Header: "employee name",
        accessor: "employee_name",
        width: "40%",
        align: "left",
      },
      { Header: "designation", accessor: "designation", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "joining date", accessor: "joining_date", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: empInfo.map((info, index) => {
      return {
        key: { index },
        employee_code: (
          <MDTypography
            display="block"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {info.attributes.empCode}
          </MDTypography>
        ),
        employee_name: (
          <EmployeeName
            image={info?.attributes?.Photo?.data?.[0]?.attributes?.url}
            name={info.attributes.name}
            email={info.attributes.email}
          />
        ),
        designation: <Job title={info.attributes.designation} />,
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={info.attributes.status}
              color={info.attributes.status === "active" ? "success" : "error"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        joining_date: (
          <MDTypography
            display="block"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {info.attributes.joiningDate}
          </MDTypography>
        ),
        action: (
          <MDButton
            variant="text"
            color="text"
            onClick={() => {
              hadleEdit(info.id);
            }}
          >
            <Icon>edit</Icon>&nbsp;edit
          </MDButton>
        ),
      };
    }),
  };
}
