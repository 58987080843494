import React, { useEffect } from "react";
import "./App.css";
import "react-toastify";
import backGroundImage from "./assets/images/backimage.webp";
import { Routes, Route } from "react-router-dom";
import AuthProvider from "./context/AuthProvider";
import { useMaterialUIController } from "./context/MaterialUIProvider";
import Dashboard from "./layouts/dashboard";
import ThemeComponent from "./components/Theme";
import Tables from "./layouts/tables";
import Profile from "./layouts/profile";
import Billing from "./layouts/billing";
import Notifications from "./layouts/notifications";
import PrivateRoute from "./PrivateRoutes";
import AddDetails from "./examples/Form/AddDetailsForm";
import EditDetail from "./examples/Form/EditDetailForm";
import AttendanceList from "./layouts/attendanceList/index";
import HolidaysList from "./layouts/holidays";
import BlogPost from "./layouts/blogPost";
import Editor from "./examples/Editor/index";
import LoginPage from "./pages/Login";

const App: React.FC = () => {
  const [controller] = useMaterialUIController();
  const { direction } = controller;

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  return (
    <div
      className="App"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${backGroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backdropFilter: "blur(4px)",
        }}
      >
        <AuthProvider>
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route
                path="dashboard"
                element={
                  <ThemeComponent>
                    <Dashboard />
                  </ThemeComponent>
                }
              />
              <Route
                path="employees"
                element={
                  <ThemeComponent>
                    <Tables />
                  </ThemeComponent>
                }
              />
              <Route
                path="employees/register"
                element={
                  <ThemeComponent>
                    <AddDetails />
                  </ThemeComponent>
                }
              />
              <Route
                path="employees/edit/:id"
                element={
                  <ThemeComponent>
                    <EditDetail />
                  </ThemeComponent>
                }
              />
              <Route
                path="profile"
                element={
                  <ThemeComponent>
                    <Profile />
                  </ThemeComponent>
                }
              />
              <Route
                path="attendance"
                element={
                  <ThemeComponent>
                    <AttendanceList />
                  </ThemeComponent>
                }
              />
              <Route
                path="holidays"
                element={
                  <ThemeComponent>
                    <HolidaysList />
                  </ThemeComponent>
                }
              />
              <Route
                path="blog"
                element={
                  <ThemeComponent>
                    <BlogPost />
                  </ThemeComponent>
                }
              />
              <Route
                path="blog/post_blog"
                element={
                  <ThemeComponent>
                    <Editor />
                  </ThemeComponent>
                }
              />
              <Route
                path="blog/edit_blog/:id" // Route for editing a blog post
                element={
                  <ThemeComponent>
                    <Editor />
                  </ThemeComponent>
                }
              />
              <Route
                path="billing"
                element={
                  <ThemeComponent>
                    <Billing />
                  </ThemeComponent>
                }
              />
              <Route
                path="notifications"
                element={
                  <ThemeComponent>
                    <Notifications />
                  </ThemeComponent>
                }
              />
            </Route>
            <Route path="/" element={<LoginPage />} />
          </Routes>
        </AuthProvider>
      </div>
    </div>
  );
};

export default App;
