/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FroalaEditorComponent from "react-froala-wysiwyg";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import { useMutation } from "../../api/customApi";
import { apiendpoint } from "../../api/endpoint";
import { toast } from "react-toastify";
import MDBox from "../../components/MDBox/MDBox";
import MDButton from "../../components/MDButton";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import MDTypography from "../../components/MDTypography";
import { Grid, Icon, IconButton } from "@mui/material";
import { Card, Image } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import MDInput from "../../components/MDInput";
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const Editor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [model, setModel] = useState("");
  const [jsonView, setJsonView] = useState(null);
  const [imagePath, setImagePath] = useState("");
  const [uploadedImage, setUploadedImage] = useState("");
  const [existingImageId, setExistingImageId] = useState("");
  const [inputValue, setInputValue] = useState({
    metaTitle: "",
    metaDescr: "",
    title: "",
    description: "",
    blogDate: "",
  });
  const { makeRequest } = useMutation();

  useEffect(() => {
    if (id) {
      fetchBlogPost(id); // Fetch blog post data if ID exists
    }
  }, [id]);

  const fetchBlogPost = async (id) => {
    try {
      const response = await makeRequest({
        type: "get",
        url: apiendpoint.editBlogs(id), // Adjust endpoint as needed
      });
      if (response) {
        console.log(response.data, "rearrange");
        setInputValue({
          metaTitle: response.data.attributes.metaTitle,
          metaDescr: response.data.attributes.metaDescr,
          title: response.data.attributes.title,
          description: response.data.attributes.shortDesc,
          blogDate: response.data.attributes.BlogDate,
        });
        setModel(response.data.attributes.blogData);
        setExistingImageId(response.data.attributes.banner?.data?.id);
        // Load image if available
        setImagePath(
          `${process.env.REACT_APP_BASE_URL}` +
            response.data.attributes.banner?.data?.attributes.url
        );
      }
    } catch (error) {
      console.error("Error fetching blog post:", error);
      toast.error("Failed to fetch blog post.");
    }
  };

  // Model changes
  const handleModelChange = (e) => {
    setModel(e);
    setJsonView(JSON.stringify({ model: e }, null, 2)); // Pretty-print JSON
  };

  // Navigation
  const handleClose = () => navigate("/blog");
  // Get the image url by id
  const getImageUrlById = async (id) => {
    try {
      const urlResponse = await makeRequest({
        type: "get",
        url: apiendpoint.getuploadImg(id),
      });
      if (urlResponse) {
        return `${process.env.REACT_APP_BASE_URL}` + urlResponse.url;
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Configurations for Froalo Editor
  const configFroala = {
    placeholderText: "Write here...",
    codeView: true,
    paragraphFormatSelection: true,
    toolbarSticky: false,
    toolbarBottom: true,
    quickInsertTags: [
      "p",
      "div",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "pre",
      "blockquote",
    ],
    htmlAllowedTags: [
      "a",
      "abbr",
      "address",
      "area",
      "article",
      "aside",
      "audio",
      "b",
      "base",
      "bdi",
      "bdo",
      "blockquote",
      "br",
      "button",
      "canvas",
      "caption",
      "cite",
      "code",
      "col",
      "colgroup",
      "datalist",
      "dd",
      "del",
      "details",
      "dfn",
      "dialog",
      "div",
      "dl",
      "dt",
      "em",
      "embed",
      "fieldset",
      "figcaption",
      "figure",
      "footer",
      "form",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "header",
      "hgroup",
      "hr",
      "i",
      "iframe",
      "img",
      "input",
      "ins",
      "kbd",
      "keygen",
      "label",
      "legend",
      "li",
      "link",
      "main",
      "map",
      "mark",
      "menu",
      "menuitem",
      "meter",
      "nav",
      "noscript",
      "object",
      "ol",
      "optgroup",
      "option",
      "output",
      "p",
      "param",
      "pre",
      "progress",
      "queue",
      "rp",
      "rt",
      "ruby",
      "s",
      "samp",
      "script",
      "style",
      "section",
      "select",
      "small",
      "source",
      "span",
      "strike",
      "strong",
      "sub",
      "summary",
      "sup",
      "table",
      "tbody",
      "td",
      "textarea",
      "tfoot",
      "th",
      "thead",
      "time",
      "title",
      "tr",
      "track",
      "u",
      "ul",
      "var",
      "video",
      "wbr",
    ],
    charCounterCount: false,
    imageUploadURL: apiendpoint.uploadImg,
    imageUploadParam: "files",
    imageUploadRemoteUrls: false,
    imageAllowedTypes: ["jpeg", "jpg", "png", "gif"],
    wordCounterCount: false,
    events: {
      "image.uploaded": async function (response) {
        try {
          const res = JSON.parse(response);
          if (res && res.length > 0) {
            const imageResponse = await getImageUrlById(res[0].id);
            if (imageResponse) {
              this.image.insert(imageResponse);
              this.image.remove(); // Remove the blob image
            }
          }
        } catch (error) {
          console.log("Error parsing image upload response:", error);
        }
      },
    },
  };

  // Save Post Button
  const handleSavePost = async () => {
    try {
      const uploadBannerId = await handleUploadbanner();
      console.log("Banner uploaded ID:", uploadBannerId);
      if (uploadBannerId) {
        try {
          const response = await makeRequest({
            type: id ? "put" : "post",
            url: id ? apiendpoint.editBlogs(id) : apiendpoint.postBlogs,

            body: {
              data: {
                metaTitle: inputValue.metaTitle,
                metaDescr: inputValue.metaDescr,
                BlogDate: inputValue.blogDate,
                title: inputValue.title,
                shortDesc: inputValue.description,
                banner: [uploadBannerId],
                blogData: model,
              },
            },
          });
          if (response) {
            toast.success("Content saved successfully!");
            navigate("/blog", { state: { refreshed: true } });
            console.log("Saved content response:", response);
          }
        } catch (err) {
          throw err;
        }
      }
    } catch (err) {
      console.log("Error saving content:", err);
      toast.error("Failed to save content.");
    }
  };

  // Banner/Poster upload
  const handleUploadbanner = async () => {
    if (uploadedImage) {
      console.log(uploadedImage, "uploading..");
      const formData = new FormData();
      formData.append("files", uploadedImage);
      try {
        const uploadImageResponse = await axios.post(
          apiendpoint.uploadImg,
          formData
        );
        console.log(uploadImageResponse.data, "upload");
        return uploadImageResponse.data[0]?.id || null;
      } catch (error) {
        toast.error("Image upload failed.");
        console.error("Image upload error:", error);
        return null;
      }
    }
    return existingImageId;
  };

  // Banner/Poster Preview
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imgPath = URL.createObjectURL(file);
      console.log(imgPath);
      setImagePath(imgPath);
      setUploadedImage(file); // Set image uploaded state to true
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}></Grid>
        </Grid>
        <Card>
          <MDBox
            display="grid"
            variant="gradient"
            bgColor="warning"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography
              gridRow="1"
              gridColumn="3/7"
              variant="h5"
              fontWeight="medium"
              color="white"
              mt={1}
            >
              Blog Post
            </MDTypography>
            <MDTypography
              gridRow="2"
              gridColumn="3/7"
              variant="button"
              color="white"
            >
              Blog Post Editor
            </MDTypography>
            <MDTypography gridColumn="8" gridRow="1/2" variant="button">
              <IconButton size="medium" color="white" onClick={handleClose}>
                <Icon fontSize="large" fontWeight="900">
                  close
                </Icon>
              </IconButton>
            </MDTypography>
          </MDBox>
          <MDBox variant="gradient" mx={2} mt={3} mb={1}>
            {/* Meta tag data */}
            <MDBox display="grid" mb={2}>
              <MDBox my={2} gridRow="1" gridColumn="1/4">
                <MDInput
                  type="text"
                  label="Meta Title"
                  variant="standard"
                  value={inputValue.metaTitle}
                  onChange={(e) =>
                    setInputValue({ ...inputValue, metaTitle: e.target.value })
                  }
                  fullWidth
                />
              </MDBox>
              <MDBox my={2} gridRow="1" gridColumn="5/10">
                <MDInput
                  type="text"
                  label="Meta Description"
                  variant="standard"
                  value={inputValue.metaDescr}
                  onChange={(e) =>
                    setInputValue({
                      ...inputValue,
                      metaDescr: e.target.value,
                    })
                  }
                  multiline
                  fullWidth
                />
              </MDBox>
              <MDBox my={2} gridRow="1" gridColumn="11/12">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Publish Date"
                    format="DD-MM-YYYY"
                    slotProps={{
                      textField: { variant: "standard", fullWidth: true },
                    }}
                    value={dayjs(inputValue.blogDate)}
                    onChange={(e) => {
                      setInputValue({
                        ...inputValue,
                        blogDate: e.format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </LocalizationProvider>
              </MDBox>
            </MDBox>
            {/* End Meta tag Data */}
            <MDBox display="grid" mb={2}>
              <MDBox my={2} gridRow="1" gridColumn="1/4">
                <MDInput
                  type="text"
                  label="Blog Title"
                  variant="standard"
                  value={inputValue.title}
                  onChange={(e) =>
                    setInputValue({ ...inputValue, title: e.target.value })
                  }
                  multiline
                  fullWidth
                />
              </MDBox>
              <MDBox my={2} gridRow="1" gridColumn="5/12">
                <MDInput
                  type="text"
                  label="Short Description:"
                  variant="standard"
                  value={inputValue.description}
                  onChange={(e) =>
                    setInputValue({
                      ...inputValue,
                      description: e.target.value,
                    })
                  }
                  multiline
                  fullWidth
                />
              </MDBox>
            </MDBox>
            <MDBox color="gray" display="flex" gap={6}>
                  <MDTypography pb={2}>Upload Blog Banner:</MDTypography>
                  <MDInput
                    type="file"
                    variant="standard"
                    accept="image/*"
                    onChange={handleImageChange}
                  />

                </MDBox>
                  {imagePath && (
                    <MDBox
                      mt={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="rounded-md"
                      height="400px"
                    >
                      <Image
                        src={imagePath}
                        alt="image preview"
                        className="max-h-full max-w-full rounded-lg"
                      />
                    </MDBox>
                  )}
            <hr />
            <MDBox color="gray">
              <MDTypography py={2}>Text Editor:</MDTypography>
              <FroalaEditorComponent
                tag="textarea"
                config={configFroala}
                attribution={false}
                onModelChange={(e) => handleModelChange(e)}
                model={model}
              />
              <MDBox mt={2} display="flex" gap={3}>
                <MDButton
                  onClick={handleSavePost}
                  variant="gradient"
                  color="warning"
                  type="submit"
                >
                  {id ? "Update Post" : "Save Post"}
                </MDButton>
              </MDBox>
            </MDBox>

            {/* Display the view */}
            {model && (
              <MDBox className="mt-4 p-4 bg-white rounded border">
                <MDTypography variant="h5">Preview:</MDTypography>
                <FroalaEditorView model={model} />
              </MDBox>
            )}

            {/* Display the JSON view */}
            {jsonView && (
              <MDBox className="mt-4 p-4 bg-white rounded border">
                <MDTypography variant="h5" className="font-bold">
                  JSON Content:
                </MDTypography>
                <MDTypography
                  variant="pre"
                  className="whitespace-pre-wrap text-base"
                >
                  {jsonView}
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default Editor;
