/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox/MDBox";
import { Card, Grid, Icon } from "@mui/material";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "../../api/customApi";
import { apiendpoint } from "../../api/endpoint";
import { Image } from "react-bootstrap";

function BlogPost() {
  const navigate = useNavigate();
  const handleAddPost = () => {
    navigate(`/blog/post_blog`);
  };

  const [posts, setPosts] = useState([]);
  const { makeRequest } = useMutation();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.refreshed) {
      fetchPosts();
    } else {
      fetchPosts();
    }
  }, [location.state]);

  const fetchPosts = async () => {
    try {
      const response = await makeRequest({
        type: "get",
        url: apiendpoint.getBlogsList,
      });
      if (response) {
        setPosts(response.data);
        console.log("Data fetched successfully", response.data);
      } else {
        console.log("No Data Available");
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    }
  };

  const onPressEdit = (id) => {
    navigate(`/blog/edit_blog/${id}`);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Do you want to delete?")) {
      if (id) {
        try {
          const response = await makeRequest({
            type: "delete",
            url: apiendpoint.deleteBlogs(id),
          });
          if (response) {
            fetchPosts();
          }
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      fetchPosts();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} />
        </Grid>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
            variant="gradient"
            bgColor="warning"
            borderRadius="lg"
            coloredShadow="dark"
          >
            <MDTypography variant="h6" color="white">
              Blog List
            </MDTypography>
            <MDButton variant="gradient" onClick={handleAddPost}>
              Post New Blog
            </MDButton>
          </MDBox>
          <MDBox p={2}>
            <MDTypography variant="h4">Blogs</MDTypography>
            <MDTypography variant="body1">List of posts</MDTypography>
            {posts.map((data, index) => {
                           let imageurl = `${process.env.REACT_APP_BASE_URL}${data.attributes.banner.data?.attributes.formats.medium.url}`;
                           let date = `${data.attributes.publishedAt.toString().split("T")[0]}`
                           let shortDesc =`${data.attributes.shortDesc.slice(0, 150)}`
                           let herf = `https://xyz.studio/blog/${data.id}`
            return(
            <div
              key={data.id}
              className="box shadow-[0px_0px_4px_0px_rgba(244,244,244,0.1)] border-2 rounded-[30px] p-4 flex  justify-center items-center mt-3"
              animation-item={`${(index + 1) * 80}px`}
            >
              {/* Image */}
              <div className="relative w-[480px] h-[80%] overflow-hidden bg-cover bg-no-repeat rounded-[30px]">
                <Image
                  src={imageurl}
                  className="transition duration-1000 ease-in-out hover:scale-125 w-full h-[80%]"
                  alt="Louvre"
                  width={100}
                  height={100}
                />
              </div>
              {/* Blog Content */}
              <div className=" flex flex-col flex-1 py-[11px] pl-[24px] pr-[16px]">
                <span className="text-[12px] leading-5 text-disabled mt-1 ">
                  {date}
                </span>
                <h2 className=" font-semibold text-black mt-1 text-[24px] leading-10">
                  {data.attributes.title}
                </h2>
                <p className="text-[20px] leading-[28px] my-3 text-disabled">
                  {shortDesc}...<Link to={herf} className="text-orange-500">View Blog</Link>
                </p>
              </div>
            <div className="flex flex-col ">
            <MDButton
            variant="text"
            color="text"
            size="medium"
            onClick={() => onPressEdit(data.id)}
          >
            <Icon>edit</Icon>&nbsp;edit
          </MDButton>
          <MDButton
            variant="text"
            color="error"
            size="medium"
            onClick={() => handleDelete(data.id)}
          >
            <Icon>delete</Icon>&nbsp;delete
          </MDButton>
            </div>
            </div>
          )})}
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default BlogPost;
