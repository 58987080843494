import Dashboard from "./layouts/dashboard";
import Billing from "./layouts/billing";
import Notifications from "./layouts/notifications";
import Profile from "./layouts/profile";
import Login from "./pages/Login";
import Employees from "./layouts/tables";
import Icon from "@mui/material/Icon";
// import AttendanceList from "./layouts/attendanceList/index";
import HolidaysList from "./layouts/holidays";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Employees",
    key: "employees",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/employees",
    component: <Employees />,
  },
  // {
  //   type: "collapse",
  //   name: "Daily Attendance",
  //   key: "attendance",
  //   icon: <Icon fontSize="small">list_alt</Icon>,
  //   route: "/attendance",
  //   component: <AttendanceList />,
  // },
  {
    type: "collapse",
    name: "Holidays",
    key: "holidays",
    icon: <Icon fontSize="small">calendar_month</Icon>,
    route: "/holidays",
    component: <HolidaysList />,
  },
  {
    type: "collapse",
    name: "Blog List",
    key: "blog",
    icon: <Icon fontSize="small">chat</Icon>,
    route: "/blog",
    component: <HolidaysList />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },

  {
    type: "collapse",
    name: "Log Out",
    key: "log-out",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/",
    component: <Login />,
  },
];

export default routes;
