import React from "react";
import Asrto from "../assets/images/curved.webp";
import logo from "../assets/images/logo-ct.webp";
import Email from "../assets/images/email.svg";
import Lock from "../assets/images/lock.svg";
import Insta from "../assets/images/instagram.svg";
import Facebook from "../assets/images/facebook.svg";
import LinkedIn from "../assets/images/linkedin.svg";
import Button from "../components/Button/Button";
import { Image } from "react-bootstrap";
import Checkbox from "../components/Checkbox/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, FormikHelpers } from "formik";
import axios from "axios";
import { useAuth } from "../context/AuthProvider";
import { apiendpoint } from "../api/endpoint";
import { toast, ToastContainer } from "react-toastify";

interface LoginValues {
  identifier: string;
  password: string;
}

const LoginPage = () => {
  const navigate = useNavigate();
  const initialValues = useAuth();
  // const { makeRequest, data, isError, isLoading, error } = useMutation();
  localStorage.removeItem("auth");
  const handleLogin = async (
    values: LoginValues,
    { setErrors }: FormikHelpers<LoginValues>
  ): Promise<void> => {
    try {
      const response = await axios.post(apiendpoint.login, {
        identifier: values.identifier,
        password: values.password,
      });
      // console.log(response.data);
      const data = response.data;
      localStorage.setItem("auth", data.jwt);
      localStorage.setItem("Id", data.user.id);
      localStorage.setItem("User_Name", data.user.username);
      localStorage.setItem("Identifier", data.user.email);
      localStorage.setItem("User_type", data.user.user_type);
      if (data.jwt !== "") {
        navigate("dashboard");
      } else {
        console.log("enter into 12332");
        navigate("/signup");
      }
    } catch (error) {
      console.error(error);
      toast.error("Login failed. Please try again!");
    }
  };

  const validate = (values: LoginValues) => {
    const errors: Partial<LoginValues> = {};
    if (!values.identifier) {
      errors.identifier = "Please enter your email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.identifier)
    ) {
      errors.identifier = "Invalid email address";
    }

    if (!values.password) {
      errors.password = "Please enter your password";
    } else if (!values.password.match("Demo@123")) {
      errors.password = "Invalid password ";
    }

    return errors;
  };

  return (
    <div className="flex flex-col md:flex-row justify-center items-center my-16  mx-6">
      <Image
        src={Asrto}
        alt="Background"
        className="hidden md:block md:h-[79vh] object-cover w-full md:w-[36%] rounded-t-lg md:rounded-l-lg md:rounded-tr-none"
      />
      <Formik 
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleLogin}
      >
        {({ errors, touched }) => (
          <Form className="bg-white flex flex-col justify-center items-center  m-0 md:max-sm:justify-self-center md:h-[79vh] md:w-[40%] rounded  md:rounded-r-lg md:rounded-bl-none md:rounded-tl-none p-[29px] md:pt-[30px] relative">
            <div className="md:p-[34px] flex flex-col">
              <img
                src={logo}
                className=" w-[65%] mb-6 object-cover"
                alt="Logo"
              />
              <h2 className="text-gray-800 font-bold text-2xl md:text-3xl">
                Log in to your Account
              </h2>
              <span className="text-orange-500 mt-2">
                Welcome back! Please login
              </span>

              <div className="mt-6 flex items-center p-3 gap-2 border border-gray-200 bg-gray-100 rounded-md w-full">
                <img src={Email} alt="Email" />
                <Field
                  id="identifier"
                  name="identifier"
                  type="email"
                  placeholder="Email-ID"
                  className="flex-1 bg-transparent outline-none"
                />
              </div>
              {errors.identifier && touched.identifier && (
                <p className="text-red-500 text-xs">{errors.identifier}</p>
              )}

              <div className="mt-5 flex items-center p-3 gap-2 border border-gray-200 bg-gray-100 rounded-md w-full">
                <img src={Lock} alt="Password" />
                <Field
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  className="flex-1 bg-transparent outline-none"
                />
              </div>
              {errors.password && touched.password && (
                <p className="text-red-500 text-xs">{errors.password}</p>
              )}

              <div className="mb-3 mt-3 flex justify-between text-xs w-full text-slate-400">
                <Checkbox label="Remember Password" />
              </div>

              <div className="mt-4 w-full">
                <Button
                  label="Login"
                  type="submit"
                  className="bg-orange-500 border-orange-500 rounded-md h-12 text-white w-full"
                  onClick={() => handleLogin}
                />
              </div>

              <div className="mt-2 text-center text-xs text-slate-400">
                Don't have an account? &nbsp;
                <Link to="/signup" className="text-orange-500">
                  Create an Account
                </Link>
              </div>

              <div className="flex items-center w-full mt-6">
                <hr className="flex-1" />
                <div className="px-2 bg-white flex space-x-2">
                  <Link
                    to="https://in.linkedin.com/company/xyzdotstudio"
                    target="_blank"
                  >
                    <img src={Insta} alt="Instagram" />
                  </Link>
                  <Link
                    to="https://www.facebook.com/xyzdotstudio/"
                    target="_blank"
                  >
                    <img src={Facebook} alt="Facebook" />
                  </Link>
                  <Link
                    to="https://www.instagram.com/xyzdotstudio/"
                    target="_blank"
                  >
                    <img src={LinkedIn} alt="LinkedIn" />
                  </Link>
                </div>
                <hr className="flex-1" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ToastContainer
        icon={false}
        style={{
          width: "30vw",
        }}
      />
    </div>
  );
};

export default LoginPage;
