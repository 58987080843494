/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox/MDBox";
import { Card, Grid, Icon, Skeleton } from "@mui/material";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "../../api/customApi";
import { apiendpoint } from "../../api/endpoint";
import { Image } from "react-bootstrap";
import moment from "moment";

function BlogPost() {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const { makeRequest, isLoading } = useMutation();
  const location = useLocation();

  useEffect(() => {
    fetchPosts();
  }, [location.state]);

  const fetchPosts = async () => {
    try {
      const response = await makeRequest({
        type: "get",
        url: apiendpoint.getBlogsList,
      });
      if (response) {
        setPosts(response.data);
        console.log("Data fetched successfully", response.data);
      } else {
        console.log("No Data Available");
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const handleAddPost = () => navigate(`/blog/post_blog`);

  const onPressEdit = (id) => navigate(`/blog/edit_blog/${id}`);

  const handleDelete = async (id) => {
    if (window.confirm("Do you want to delete?") && id) {
      try {
        const response = await makeRequest({
          type: "delete",
          url: apiendpoint.deleteBlogs(id),
        });
        if (response) fetchPosts();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} />
        </Grid>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
            variant="gradient"
            bgColor="warning"
            borderRadius="lg"
            coloredShadow="dark"
          >
            <MDTypography variant="h6" color="white">
              Blog List
            </MDTypography>
            <MDButton variant="gradient" onClick={handleAddPost}>
              Post New Blog
            </MDButton>
          </MDBox>
          <MDBox p={2} overflow="scroll" maxHeight="73vh"  className="scrollbar-none">
            {isLoading ? (
              <div className="box shadow-[0px_0px_4px_0px_rgba(244,244,244,0.1)] border-2 rounded-[30px] p-4 flex mt-3 gap-x-4">
                <Skeleton animation="pulse" width={480} height={200} variant="rounded" />
                <div className="flex flex-col gap-1">
                  {[100, 800, 700, 500].map((width, index) => (
                    <Skeleton key={index} animation="pulse" width={width} height={index === 0 ? 40 : 30} variant="text" />
                  ))}
                </div>
              </div>
            ) : (
              posts.map((data) => {
                const imageurl = `${process.env.REACT_APP_BASE_URL}${data.attributes.banner.data?.attributes.formats.medium.url}`;
                const date = moment(data.attributes.BlogDate).format("DD/MM/YYYY");
                const shortDesc = data.attributes.shortDesc;
                const herf = `https://xyz.studio/blog/${data.attributes.title.replaceAll(/ /g, "-")}`;

                return (
                  <div key={data.id} className="box shadow-[0px_0px_4px_0px_rgba(244,244,244,0.1)] border-2 rounded-[30px] p-4 flex justify-center items-center mt-3 max-h-[300px]">
                    <div className="relative w-[480px] h-[75%] overflow-hidden bg-cover bg-no-repeat rounded-[30px]">
                      <Image src={imageurl} className="transition duration-1000 ease-in-out hover:scale-125 w-full h-[75%]" alt="Blog" />
                    </div>
                    <div className="flex flex-col flex-1 py-[11px] pl-[24px] pr-[16px]">
                      <span className="text-[12px] leading-5 text-disabled mt-1">{date ?? "null"}</span>
                      <h2 className="font-semibold text-black mt-1 text-[24px] leading-10">{data.attributes.title}</h2>
                      <p className="text-[20px] leading-[28px] mt-3 text-disabled line-clamp-3">{shortDesc}</p>
                      <Link to={herf} className="text-orange-500">View Blog</Link>
                    </div>
                    <div className="flex flex-col">
                      <MDButton variant="text" color="text" size="medium" onClick={() => onPressEdit(data.id)}>
                        <Icon>edit</Icon>&nbsp;edit
                      </MDButton>
                      <MDButton variant="text" color="error" size="medium" onClick={() => handleDelete(data.id)}>
                        <Icon>delete</Icon>&nbsp;delete
                      </MDButton>
                    </div>
                  </div>
                );
              })
            )}
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default BlogPost;