import React from "react";

interface ButtonProps {
  label: string;
  type?: "button" | "reset" | "submit" | undefined;
  className?: string;
  onClick:React.MouseEventHandler<HTMLButtonElement>
}

const Button: React.FC<ButtonProps> = ({ type, label, className, onClick }) => {
  return (
    <button onClick={onClick} type={type} className={className}>
      {label}
    </button>
  );
};

export default Button;
