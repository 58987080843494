import React, { useState } from "react";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox/MDBox";
import { Card, Grid, Icon, IconButton, Modal } from "@mui/material";
import holidaysTableData from "./data/holidaysTableData";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import DataTable from "../../examples/Tables/DataTable";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import MDInput from "../../components/MDInput";
import dayjs from "dayjs";
import { useMutation } from "../../api/customApi";
import { apiendpoint } from "../../api/endpoint";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function HolidaysList() {
  const token = localStorage.getItem("auth");
  const navigate = useNavigate();
  const { columns, rows } = holidaysTableData();
  const [show, setShow] = useState(false);
  const { makeRequest } = useMutation();

  const [inputValue, setInputValue] = useState({
    Name: "",
    date: "",
  });

  // hide the popups
  const handleClose = () => setShow(false);

  const handleAddHoliday = async (e) => {
    e.preventDefault();
    try {
      const holidayResponse = await makeRequest({
        type: "post",
        url: apiendpoint.postHolidays,
        token: token,
        body: {
          data: {
            ...inputValue,
          },
        },
      });
      if (holidayResponse) {
        toast.success("Holiday Added Successfully!");
        setInputValue(holidayResponse.data);
        setShow(false);
        navigate("/holidays", { state: { refreshed: true } });
      }
    } catch (error) {
      toast.error("Failed to add holiday. Please try again.");
      console.error("Error adding holiday:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}></Grid>
        </Grid>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
            variant="gradient"
            bgColor="warning"
            borderRadius="lg"
            coloredShadow="dark"
          >
            <MDTypography variant="h6" color="white">
              Holidays List
            </MDTypography>
            <MDButton variant="gradient" size="small" onClick={()=>setShow(true)}>
              Add Holiday
            </MDButton>
          </MDBox>
          <Modal
            keepMounted
            open={show}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <MDBox>
              <Card
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  boxShadow: 1,
                  borderRadius: 5,
                  p: 2,
                  width: "40vw",
                  minWidth: 300,
                }}
              >
                <MDBox
                  display="grid"
                  variant="gradient"
                  bgColor="warning"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={2}
                  mt={-3}
                  p={3}
                  mb={1}
                  textAlign="center"
                >
                  <MDTypography
                    gridRow="1"
                    gridColumn="3/7"
                    variant="h5"
                    fontWeight="medium"
                    color="white"
                    mt={1}
                  >
                    Holidays of Year
                  </MDTypography>
                  <MDTypography
                    gridRow="2"
                    gridColumn="3/7"
                    variant="button"
                    color="white"
                  >
                    Add Holiday
                  </MDTypography>
                  <MDTypography gridColumn="8" gridRow="1/2" variant="button">
                    <IconButton
                      size="medium"
                      color="white"
                      onClick={handleClose}
                    >
                      <Icon fontSize="large" fontWeight="900">
                        close
                      </Icon>
                    </IconButton>
                  </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                  <MDBox
                    component="form"
                    role="form"
                    display="grid"
                    onSubmit={handleAddHoliday}
                  >
                    <MDBox mb={1} gridRow="1" gridColumn="1/2">
                      <MDInput
                        type="text"
                        label="Holiday Name"
                        variant="standard"
                        value={inputValue.Name}
                        onChange={(e) => {
                          setInputValue((prev) => ({
                            ...prev,
                            Name: e.target.value,
                          }));
                        }}
                        fullWidth
                      />
                    </MDBox>
                    <MDBox mb={1} ml={6} gridRow="1" gridColumn="2/4">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Holiday Date"
                          slotProps={{
                            textField: {
                              variant: "standard",
                              fullWidth: true,
                            },
                          }}
                          value={dayjs(inputValue.date)}
                          onChange={(e) => {
                            console.log(e.format("YYYY-MM-DD"), "today date");
                            setInputValue((prev) => ({
                              ...prev,
                              date: e.format("YYYY-MM-DD"),
                            }));
                          }}
                        />
                      </LocalizationProvider>
                    </MDBox>
                    <MDBox mb={1} gridRow="2" gridColumn="3/4">
                      <MDButton
                        size="small"
                        variant="gradient"
                        color="light"
                        onClick={() => setShow(false)}
                      >
                        Close
                      </MDButton>{" "}
                      <MDButton
                        size="small"
                        variant="gradient"
                        color="warning"
                        type="submit"
                      >
                        Add
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </MDBox>
          </Modal>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default HolidaysList;
